export default {
    "blaIMP-58": {
        "display_name": "blaIMP-58",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-27": {
        "display_name": "blaIMP-27",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-30": {
        "display_name": "blaIMP-30",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-99": {
        "display_name": "blaIMP-99",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-45": {
        "display_name": "blaIMP-45",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-91": {
        "display_name": "blaIMP-91",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-24": {
        "display_name": "blaIMP-24",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-80": {
        "display_name": "blaIMP-80",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-88": {
        "display_name": "blaIMP-88",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-43": {
        "display_name": "blaIMP-43",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-63": {
        "display_name": "blaIMP-63",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-89": {
        "display_name": "blaIMP-89",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-6": {
        "display_name": "blaIMP-6",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-7": {
        "display_name": "blaIMP-7",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-28": {
        "display_name": "blaIMP-28",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-73": {
        "display_name": "blaIMP-73",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-35": {
        "display_name": "blaIMP-35",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-54": {
        "display_name": "blaIMP-54",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-51": {
        "display_name": "blaIMP-51",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-98": {
        "display_name": "blaIMP-98",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-3": {
        "display_name": "blaIMP-3",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-81": {
        "display_name": "blaIMP-81",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-16": {
        "display_name": "blaIMP-16",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-69": {
        "display_name": "blaIMP-69",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-77": {
        "display_name": "blaIMP-77",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-60": {
        "display_name": "blaIMP-60",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-31": {
        "display_name": "blaIMP-31",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-65": {
        "display_name": "blaIMP-65",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-18": {
        "display_name": "blaIMP-18",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-59": {
        "display_name": "blaIMP-59",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-40": {
        "display_name": "blaIMP-40",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-78": {
        "display_name": "blaIMP-78",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-93": {
        "display_name": "blaIMP-93",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-42": {
        "display_name": "blaIMP-42",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-68": {
        "display_name": "blaIMP-68",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-94": {
        "display_name": "blaIMP-94",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-83": {
        "display_name": "blaIMP-83",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-100": {
        "display_name": "blaIMP-100",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-62": {
        "display_name": "blaIMP-62",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-52": {
        "display_name": "blaIMP-52",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-23": {
        "display_name": "blaIMP-23",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-4": {
        "display_name": "blaIMP-4",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-11": {
        "display_name": "blaIMP-11",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-75": {
        "display_name": "blaIMP-75",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-13": {
        "display_name": "blaIMP-13",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-101": {
        "display_name": "blaIMP-101",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-17": {
        "display_name": "blaIMP-17",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-55": {
        "display_name": "blaIMP-55",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-74": {
        "display_name": "blaIMP-74",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-37": {
        "display_name": "blaIMP-37",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-5": {
        "display_name": "blaIMP-5",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-61": {
        "display_name": "blaIMP-61",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-2": {
        "display_name": "blaIMP-2",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-64": {
        "display_name": "blaIMP-64",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-26": {
        "display_name": "blaIMP-26",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-82": {
        "display_name": "blaIMP-82",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-102": {
        "display_name": "blaIMP-102",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-95": {
        "display_name": "blaIMP-95",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-9": {
        "display_name": "blaIMP-9",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-67": {
        "display_name": "blaIMP-67",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-96": {
        "display_name": "blaIMP-96",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-19": {
        "display_name": "blaIMP-19",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-76": {
        "display_name": "blaIMP-76",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-33": {
        "display_name": "blaIMP-33",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-21": {
        "display_name": "blaIMP-21",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-34": {
        "display_name": "blaIMP-34",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-20": {
        "display_name": "blaIMP-20",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-22": {
        "display_name": "blaIMP-22",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-29": {
        "display_name": "blaIMP-29",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-44": {
        "display_name": "blaIMP-44",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-46": {
        "display_name": "blaIMP-46",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-70": {
        "display_name": "blaIMP-70",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-66": {
        "display_name": "blaIMP-66",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-39": {
        "display_name": "blaIMP-39",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-90": {
        "display_name": "blaIMP-90",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-1": {
        "display_name": "blaIMP-1",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-53": {
        "display_name": "blaIMP-53",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-85": {
        "display_name": "blaIMP-85",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-92": {
        "display_name": "blaIMP-92",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-14": {
        "display_name": "blaIMP-14",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-56": {
        "display_name": "blaIMP-56",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-48": {
        "display_name": "blaIMP-48",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-38": {
        "display_name": "blaIMP-38",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-41": {
        "display_name": "blaIMP-41",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-71": {
        "display_name": "blaIMP-71",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-12": {
        "display_name": "blaIMP-12",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-86": {
        "display_name": "blaIMP-86",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-87": {
        "display_name": "blaIMP-87",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-79": {
        "display_name": "blaIMP-79",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-97": {
        "display_name": "blaIMP-97",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-32": {
        "display_name": "blaIMP-32",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-10": {
        "display_name": "blaIMP-10",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-25": {
        "display_name": "blaIMP-25",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-49": {
        "display_name": "blaIMP-49",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-84": {
        "display_name": "blaIMP-84",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-15": {
        "display_name": "blaIMP-15",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-8": {
        "display_name": "blaIMP-8",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaIMP-50": {
        "display_name": "blaIMP-50",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-196": {
        "display_name": "blaKPC-196",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-111": {
        "display_name": "blaKPC-111",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-139": {
        "display_name": "blaKPC-139",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-37": {
        "display_name": "blaKPC-37",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-133": {
        "display_name": "blaKPC-133",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-13": {
        "display_name": "blaKPC-13",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-34": {
        "display_name": "blaKPC-34",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-109": {
        "display_name": "blaKPC-109",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-64": {
        "display_name": "blaKPC-64",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-145": {
        "display_name": "blaKPC-145",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-106": {
        "display_name": "blaKPC-106",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-43": {
        "display_name": "blaKPC-43",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-26": {
        "display_name": "blaKPC-26",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-151": {
        "display_name": "blaKPC-151",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-195": {
        "display_name": "blaKPC-195",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-62": {
        "display_name": "blaKPC-62",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-206": {
        "display_name": "blaKPC-206",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-36": {
        "display_name": "blaKPC-36",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-32": {
        "display_name": "blaKPC-32",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-76": {
        "display_name": "blaKPC-76",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-135": {
        "display_name": "blaKPC-135",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-77": {
        "display_name": "blaKPC-77",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-144": {
        "display_name": "blaKPC-144",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-148": {
        "display_name": "blaKPC-148",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-201": {
        "display_name": "blaKPC-201",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-161": {
        "display_name": "blaKPC-161",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-202": {
        "display_name": "blaKPC-202",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-52": {
        "display_name": "blaKPC-52",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-92": {
        "display_name": "blaKPC-92",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-156": {
        "display_name": "blaKPC-156",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-165": {
        "display_name": "blaKPC-165",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-181": {
        "display_name": "blaKPC-181",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-110": {
        "display_name": "blaKPC-110",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-28": {
        "display_name": "blaKPC-28",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-48": {
        "display_name": "blaKPC-48",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-155": {
        "display_name": "blaKPC-155",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-7": {
        "display_name": "blaKPC-7",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-78": {
        "display_name": "blaKPC-78",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-142": {
        "display_name": "blaKPC-142",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-23": {
        "display_name": "blaKPC-23",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-189": {
        "display_name": "blaKPC-189",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-150": {
        "display_name": "blaKPC-150",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-179": {
        "display_name": "blaKPC-179",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-27": {
        "display_name": "blaKPC-27",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-30": {
        "display_name": "blaKPC-30",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-71": {
        "display_name": "blaKPC-71",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-58": {
        "display_name": "blaKPC-58",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-21": {
        "display_name": "blaKPC-21",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-176": {
        "display_name": "blaKPC-176",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-115": {
        "display_name": "blaKPC-115",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-158": {
        "display_name": "blaKPC-158",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-16": {
        "display_name": "blaKPC-16",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-17": {
        "display_name": "blaKPC-17",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-194": {
        "display_name": "blaKPC-194",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-81": {
        "display_name": "blaKPC-81",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-96": {
        "display_name": "blaKPC-96",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-33": {
        "display_name": "blaKPC-33",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-29": {
        "display_name": "blaKPC-29",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-128": {
        "display_name": "blaKPC-128",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-157": {
        "display_name": "blaKPC-157",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-103": {
        "display_name": "blaKPC-103",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-6": {
        "display_name": "blaKPC-6",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-129": {
        "display_name": "blaKPC-129",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-159": {
        "display_name": "blaKPC-159",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-134": {
        "display_name": "blaKPC-134",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-180": {
        "display_name": "blaKPC-180",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-14": {
        "display_name": "blaKPC-14",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-25": {
        "display_name": "blaKPC-25",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-59": {
        "display_name": "blaKPC-59",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-57": {
        "display_name": "blaKPC-57",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-10": {
        "display_name": "blaKPC-10",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-63": {
        "display_name": "blaKPC-63",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-70": {
        "display_name": "blaKPC-70",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-184": {
        "display_name": "blaKPC-184",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-163": {
        "display_name": "blaKPC-163",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-5": {
        "display_name": "blaKPC-5",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-45": {
        "display_name": "blaKPC-45",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-122": {
        "display_name": "blaKPC-122",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-67": {
        "display_name": "blaKPC-67",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-208": {
        "display_name": "blaKPC-208",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-190": {
        "display_name": "blaKPC-190",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-114": {
        "display_name": "blaKPC-114",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-105": {
        "display_name": "blaKPC-105",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-132": {
        "display_name": "blaKPC-132",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-186": {
        "display_name": "blaKPC-186",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-44": {
        "display_name": "blaKPC-44",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-90": {
        "display_name": "blaKPC-90",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-207": {
        "display_name": "blaKPC-207",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-108": {
        "display_name": "blaKPC-108",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-31": {
        "display_name": "blaKPC-31",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-127": {
        "display_name": "blaKPC-127",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-95": {
        "display_name": "blaKPC-95",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-140": {
        "display_name": "blaKPC-140",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-99": {
        "display_name": "blaKPC-99",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-149": {
        "display_name": "blaKPC-149",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-131": {
        "display_name": "blaKPC-131",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-12": {
        "display_name": "blaKPC-12",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-193": {
        "display_name": "blaKPC-193",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-104": {
        "display_name": "blaKPC-104",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-2": {
        "display_name": "blaKPC-2",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-55": {
        "display_name": "blaKPC-55",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-60": {
        "display_name": "blaKPC-60",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-120": {
        "display_name": "blaKPC-120",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-88": {
        "display_name": "blaKPC-88",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-203": {
        "display_name": "blaKPC-203",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-97": {
        "display_name": "blaKPC-97",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-51": {
        "display_name": "blaKPC-51",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-53": {
        "display_name": "blaKPC-53",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-84": {
        "display_name": "blaKPC-84",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-126": {
        "display_name": "blaKPC-126",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-192": {
        "display_name": "blaKPC-192",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-170": {
        "display_name": "blaKPC-170",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-79": {
        "display_name": "blaKPC-79",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-185": {
        "display_name": "blaKPC-185",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-130": {
        "display_name": "blaKPC-130",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-162": {
        "display_name": "blaKPC-162",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-54": {
        "display_name": "blaKPC-54",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-47": {
        "display_name": "blaKPC-47",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-82": {
        "display_name": "blaKPC-82",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-86": {
        "display_name": "blaKPC-86",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-167": {
        "display_name": "blaKPC-167",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-94": {
        "display_name": "blaKPC-94",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-166": {
        "display_name": "blaKPC-166",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-40": {
        "display_name": "blaKPC-40",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-42": {
        "display_name": "blaKPC-42",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-119": {
        "display_name": "blaKPC-119",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-138": {
        "display_name": "blaKPC-138",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-136": {
        "display_name": "blaKPC-136",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-75": {
        "display_name": "blaKPC-75",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-85": {
        "display_name": "blaKPC-85",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-118": {
        "display_name": "blaKPC-118",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-73": {
        "display_name": "blaKPC-73",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-125": {
        "display_name": "blaKPC-125",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-69": {
        "display_name": "blaKPC-69",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-153": {
        "display_name": "blaKPC-153",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-143": {
        "display_name": "blaKPC-143",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-15": {
        "display_name": "blaKPC-15",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-160": {
        "display_name": "blaKPC-160",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-68": {
        "display_name": "blaKPC-68",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-46": {
        "display_name": "blaKPC-46",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-89": {
        "display_name": "blaKPC-89",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-123": {
        "display_name": "blaKPC-123",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-154": {
        "display_name": "blaKPC-154",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-65": {
        "display_name": "blaKPC-65",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-8": {
        "display_name": "blaKPC-8",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-187": {
        "display_name": "blaKPC-187",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-74": {
        "display_name": "blaKPC-74",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-168": {
        "display_name": "blaKPC-168",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-18": {
        "display_name": "blaKPC-18",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-147": {
        "display_name": "blaKPC-147",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-100": {
        "display_name": "blaKPC-100",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-113": {
        "display_name": "blaKPC-113",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-83": {
        "display_name": "blaKPC-83",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-197": {
        "display_name": "blaKPC-197",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-204": {
        "display_name": "blaKPC-204",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-117": {
        "display_name": "blaKPC-117",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-11": {
        "display_name": "blaKPC-11",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-178": {
        "display_name": "blaKPC-178",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-56": {
        "display_name": "blaKPC-56",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-121": {
        "display_name": "blaKPC-121",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-124": {
        "display_name": "blaKPC-124",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-98": {
        "display_name": "blaKPC-98",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-38": {
        "display_name": "blaKPC-38",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-24": {
        "display_name": "blaKPC-24",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-146": {
        "display_name": "blaKPC-146",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-102": {
        "display_name": "blaKPC-102",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-182": {
        "display_name": "blaKPC-182",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-41": {
        "display_name": "blaKPC-41",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-141": {
        "display_name": "blaKPC-141",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-61": {
        "display_name": "blaKPC-61",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-116": {
        "display_name": "blaKPC-116",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-93": {
        "display_name": "blaKPC-93",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-72": {
        "display_name": "blaKPC-72",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-22": {
        "display_name": "blaKPC-22",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-4": {
        "display_name": "blaKPC-4",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-107": {
        "display_name": "blaKPC-107",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-137": {
        "display_name": "blaKPC-137",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-191": {
        "display_name": "blaKPC-191",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-39": {
        "display_name": "blaKPC-39",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-49": {
        "display_name": "blaKPC-49",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-87": {
        "display_name": "blaKPC-87",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-164": {
        "display_name": "blaKPC-164",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-35": {
        "display_name": "blaKPC-35",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-66": {
        "display_name": "blaKPC-66",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-19": {
        "display_name": "blaKPC-19",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-205": {
        "display_name": "blaKPC-205",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-50": {
        "display_name": "blaKPC-50",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-80": {
        "display_name": "blaKPC-80",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-152": {
        "display_name": "blaKPC-152",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-91": {
        "display_name": "blaKPC-91",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-183": {
        "display_name": "blaKPC-183",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-101": {
        "display_name": "blaKPC-101",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-112": {
        "display_name": "blaKPC-112",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKPC-3": {
        "display_name": "blaKPC-3",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-538": {
        "display_name": "blaOXA-538 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1012": {
        "display_name": "blaOXA-1012 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-788": {
        "display_name": "blaOXA-788 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-894": {
        "display_name": "blaOXA-894 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-247": {
        "display_name": "blaOXA-247 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-833": {
        "display_name": "blaOXA-833 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1181": {
        "display_name": "blaOXA-1181 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-245": {
        "display_name": "blaOXA-245 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1055": {
        "display_name": "blaOXA-1055 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-484": {
        "display_name": "blaOXA-484 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-244": {
        "display_name": "blaOXA-244 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-517": {
        "display_name": "blaOXA-517 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-505": {
        "display_name": "blaOXA-505 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-922": {
        "display_name": "blaOXA-922 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-199": {
        "display_name": "blaOXA-199 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-514": {
        "display_name": "blaOXA-514 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-204": {
        "display_name": "blaOXA-204 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-547": {
        "display_name": "blaOXA-547 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-162": {
        "display_name": "blaOXA-162 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1212": {
        "display_name": "blaOXA-1212 (blaOXA-48_fam)",
        "antibiotics": [
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-535": {
        "display_name": "blaOXA-535 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-918": {
        "display_name": "blaOXA-918 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-933": {
        "display_name": "blaOXA-933 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-929": {
        "display_name": "blaOXA-929 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-793": {
        "display_name": "blaOXA-793 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-567": {
        "display_name": "blaOXA-567 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-934": {
        "display_name": "blaOXA-934 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1167": {
        "display_name": "blaOXA-1167 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1205": {
        "display_name": "blaOXA-1205 (blaOXA-48_fam)",
        "antibiotics": [
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-370": {
        "display_name": "blaOXA-370 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1038": {
        "display_name": "blaOXA-1038 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-48": {
        "display_name": "blaOXA-48 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-436": {
        "display_name": "blaOXA-436 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-731": {
        "display_name": "blaOXA-731 (blaOXA-48_fam)",
        "antibiotics": [
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-546": {
        "display_name": "blaOXA-546 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-252": {
        "display_name": "blaOXA-252 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-438": {
        "display_name": "blaOXA-438 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-515": {
        "display_name": "blaOXA-515 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1119": {
        "display_name": "blaOXA-1119 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-439": {
        "display_name": "blaOXA-439 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-924": {
        "display_name": "blaOXA-924 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1213": {
        "display_name": "blaOXA-1213 (blaOXA-48_fam)",
        "antibiotics": [
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-566": {
        "display_name": "blaOXA-566 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1207": {
        "display_name": "blaOXA-1207 (blaOXA-48_fam)",
        "antibiotics": [
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1201": {
        "display_name": "blaOXA-1201 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-923": {
        "display_name": "blaOXA-923 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1146": {
        "display_name": "blaOXA-1146 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-181": {
        "display_name": "blaOXA-181 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-416": {
        "display_name": "blaOXA-416 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-405": {
        "display_name": "blaOXA-405 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-920": {
        "display_name": "blaOXA-920 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-163": {
        "display_name": "blaOXA-163 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-54": {
        "display_name": "blaOXA-54 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1039": {
        "display_name": "blaOXA-1039 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-232": {
        "display_name": "blaOXA-232 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-519": {
        "display_name": "blaOXA-519 (blaOXA-48_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1211": {
        "display_name": "blaOXA-1211 (blaOXA-48_fam)",
        "antibiotics": [
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1200": {
        "display_name": "blaOXA-1200 (blaOXA-48_fam)",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-565": {
        "display_name": "blaOXA-565 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-170": {
        "display_name": "blaOXA-170 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-105": {
        "display_name": "blaOXA-105 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-967": {
        "display_name": "blaOXA-967 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-440": {
        "display_name": "blaOXA-440 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-423": {
        "display_name": "blaOXA-423 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-808": {
        "display_name": "blaOXA-808 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1095": {
        "display_name": "blaOXA-1095 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-239": {
        "display_name": "blaOXA-239 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-811": {
        "display_name": "blaOXA-811 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-807": {
        "display_name": "blaOXA-807 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-398": {
        "display_name": "blaOXA-398 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-812": {
        "display_name": "blaOXA-812 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-167": {
        "display_name": "blaOXA-167 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-27": {
        "display_name": "blaOXA-27 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-165": {
        "display_name": "blaOXA-165 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-169": {
        "display_name": "blaOXA-169 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-816": {
        "display_name": "blaOXA-816 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-818": {
        "display_name": "blaOXA-818 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-809": {
        "display_name": "blaOXA-809 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-146": {
        "display_name": "blaOXA-146 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-435": {
        "display_name": "blaOXA-435 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-482": {
        "display_name": "blaOXA-482 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-49": {
        "display_name": "blaOXA-49 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-815": {
        "display_name": "blaOXA-815 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-366": {
        "display_name": "blaOXA-366 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-966": {
        "display_name": "blaOXA-966 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-483": {
        "display_name": "blaOXA-483 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-171": {
        "display_name": "blaOXA-171 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-810": {
        "display_name": "blaOXA-810 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-968": {
        "display_name": "blaOXA-968 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-225": {
        "display_name": "blaOXA-225 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-103": {
        "display_name": "blaOXA-103 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-168": {
        "display_name": "blaOXA-168 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-893": {
        "display_name": "blaOXA-893 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1216": {
        "display_name": "blaOXA-1216 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-133": {
        "display_name": "blaOXA-133 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-814": {
        "display_name": "blaOXA-814 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-657": {
        "display_name": "blaOXA-657 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-806": {
        "display_name": "blaOXA-806 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-1223": {
        "display_name": "blaOXA-1223 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-817": {
        "display_name": "blaOXA-817 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-969": {
        "display_name": "blaOXA-969 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-23": {
        "display_name": "blaOXA-23",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-166": {
        "display_name": "blaOXA-166 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-422": {
        "display_name": "blaOXA-422 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-813": {
        "display_name": "blaOXA-813 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-911": {
        "display_name": "blaOXA-911 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaOXA-73": {
        "display_name": "blaOXA-73 (blaOXA-23_fam)",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-6": {
        "display_name": "blaNDM-6",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-50": {
        "display_name": "blaNDM-50",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-13": {
        "display_name": "blaNDM-13",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-36": {
        "display_name": "blaNDM-36",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-54": {
        "display_name": "blaNDM-54",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-56": {
        "display_name": "blaNDM-56",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-44": {
        "display_name": "blaNDM-44",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-39": {
        "display_name": "blaNDM-39",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-10": {
        "display_name": "blaNDM-10",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-15": {
        "display_name": "blaNDM-15",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-26": {
        "display_name": "blaNDM-26",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-61": {
        "display_name": "blaNDM-61",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-7": {
        "display_name": "blaNDM-7",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-27": {
        "display_name": "blaNDM-27",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-12": {
        "display_name": "blaNDM-12",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-18": {
        "display_name": "blaNDM-18",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-25": {
        "display_name": "blaNDM-25",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-49": {
        "display_name": "blaNDM-49",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-11": {
        "display_name": "blaNDM-11",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-4": {
        "display_name": "blaNDM-4",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-46": {
        "display_name": "blaNDM-46",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-38": {
        "display_name": "blaNDM-38",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-1": {
        "display_name": "blaNDM-1",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-5": {
        "display_name": "blaNDM-5",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-55": {
        "display_name": "blaNDM-55",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-40": {
        "display_name": "blaNDM-40",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-20": {
        "display_name": "blaNDM-20",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-31": {
        "display_name": "blaNDM-31",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-51": {
        "display_name": "blaNDM-51",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-41": {
        "display_name": "blaNDM-41",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-9": {
        "display_name": "blaNDM-9",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-24": {
        "display_name": "blaNDM-24",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-37": {
        "display_name": "blaNDM-37",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-28": {
        "display_name": "blaNDM-28",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-60": {
        "display_name": "blaNDM-60",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-48": {
        "display_name": "blaNDM-48",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-42": {
        "display_name": "blaNDM-42",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-21": {
        "display_name": "blaNDM-21",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-16b": {
        "display_name": "blaNDM-16b",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-16a": {
        "display_name": "blaNDM-16a",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-34": {
        "display_name": "blaNDM-34",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-29": {
        "display_name": "blaNDM-29",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-47": {
        "display_name": "blaNDM-47",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-19": {
        "display_name": "blaNDM-19",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-58": {
        "display_name": "blaNDM-58",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-2": {
        "display_name": "blaNDM-2",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-22": {
        "display_name": "blaNDM-22",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-57": {
        "display_name": "blaNDM-57",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-35": {
        "display_name": "blaNDM-35",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-52": {
        "display_name": "blaNDM-52",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-8": {
        "display_name": "blaNDM-8",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-23": {
        "display_name": "blaNDM-23",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-14": {
        "display_name": "blaNDM-14",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-3": {
        "display_name": "blaNDM-3",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-45": {
        "display_name": "blaNDM-45",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-43": {
        "display_name": "blaNDM-43",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-17": {
        "display_name": "blaNDM-17",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-30": {
        "display_name": "blaNDM-30",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-53": {
        "display_name": "blaNDM-53",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaNDM-33": {
        "display_name": "blaNDM-33",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-52": {
        "display_name": "blaVIM-52",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-33": {
        "display_name": "blaVIM-33",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-77": {
        "display_name": "blaVIM-77",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-70": {
        "display_name": "blaVIM-70",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-69": {
        "display_name": "blaVIM-69",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-79": {
        "display_name": "blaVIM-79",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-17": {
        "display_name": "blaVIM-17",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-26": {
        "display_name": "blaVIM-26",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-44": {
        "display_name": "blaVIM-44",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-62": {
        "display_name": "blaVIM-62",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-24": {
        "display_name": "blaVIM-24",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-2": {
        "display_name": "blaVIM-2",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-80": {
        "display_name": "blaVIM-80",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-1": {
        "display_name": "blaVIM-1",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-38": {
        "display_name": "blaVIM-38",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-57": {
        "display_name": "blaVIM-57",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-55": {
        "display_name": "blaVIM-55",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-7": {
        "display_name": "blaVIM-7",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-75": {
        "display_name": "blaVIM-75",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-68": {
        "display_name": "blaVIM-68",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-60": {
        "display_name": "blaVIM-60",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-61": {
        "display_name": "blaVIM-61",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-14": {
        "display_name": "blaVIM-14",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-37": {
        "display_name": "blaVIM-37",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-54": {
        "display_name": "blaVIM-54",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-12": {
        "display_name": "blaVIM-12",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-6": {
        "display_name": "blaVIM-6",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-9": {
        "display_name": "blaVIM-9",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-49": {
        "display_name": "blaVIM-49",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-42": {
        "display_name": "blaVIM-42",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-28": {
        "display_name": "blaVIM-28",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-23": {
        "display_name": "blaVIM-23",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-45": {
        "display_name": "blaVIM-45",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-65": {
        "display_name": "blaVIM-65",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-76": {
        "display_name": "blaVIM-76",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-64": {
        "display_name": "blaVIM-64",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-11": {
        "display_name": "blaVIM-11",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-18": {
        "display_name": "blaVIM-18",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-34": {
        "display_name": "blaVIM-34",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-39": {
        "display_name": "blaVIM-39",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-67": {
        "display_name": "blaVIM-67",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-41": {
        "display_name": "blaVIM-41",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-27": {
        "display_name": "blaVIM-27",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-36": {
        "display_name": "blaVIM-36",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-71": {
        "display_name": "blaVIM-71",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-53": {
        "display_name": "blaVIM-53",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-16": {
        "display_name": "blaVIM-16",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-19": {
        "display_name": "blaVIM-19",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-82": {
        "display_name": "blaVIM-82",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-78": {
        "display_name": "blaVIM-78",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-8": {
        "display_name": "blaVIM-8",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-10": {
        "display_name": "blaVIM-10",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-5": {
        "display_name": "blaVIM-5",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-30": {
        "display_name": "blaVIM-30",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-29": {
        "display_name": "blaVIM-29",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-46": {
        "display_name": "blaVIM-46",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-73": {
        "display_name": "blaVIM-73",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-43": {
        "display_name": "blaVIM-43",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-20": {
        "display_name": "blaVIM-20",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-74": {
        "display_name": "blaVIM-74",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-48": {
        "display_name": "blaVIM-48",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-86": {
        "display_name": "blaVIM-86",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-31": {
        "display_name": "blaVIM-31",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-58": {
        "display_name": "blaVIM-58",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-35": {
        "display_name": "blaVIM-35",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-59": {
        "display_name": "blaVIM-59",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-63": {
        "display_name": "blaVIM-63",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-56": {
        "display_name": "blaVIM-56",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-25": {
        "display_name": "blaVIM-25",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-47": {
        "display_name": "blaVIM-47",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-50": {
        "display_name": "blaVIM-50",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-32": {
        "display_name": "blaVIM-32",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-51": {
        "display_name": "blaVIM-51",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-72": {
        "display_name": "blaVIM-72",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-3": {
        "display_name": "blaVIM-3",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-81": {
        "display_name": "blaVIM-81",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-85": {
        "display_name": "blaVIM-85",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-15": {
        "display_name": "blaVIM-15",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-4": {
        "display_name": "blaVIM-4",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-13": {
        "display_name": "blaVIM-13",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-84": {
        "display_name": "blaVIM-84",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-40": {
        "display_name": "blaVIM-40",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-66": {
        "display_name": "blaVIM-66",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaVIM-83": {
        "display_name": "blaVIM-83",
        "antibiotics": [
            "doripenem",
            "ertapenem",
            "imipenem",
            "meropenem",
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "mcr-1.36": {
        "display_name": "mcr-1.36",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.10": {
        "display_name": "mcr-1.10",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.34": {
        "display_name": "mcr-1.34",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.31": {
        "display_name": "mcr-1.31",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.5": {
        "display_name": "mcr-1.5",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.8": {
        "display_name": "mcr-1.8",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.1": {
        "display_name": "mcr-1.1",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.27": {
        "display_name": "mcr-1.27",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.4": {
        "display_name": "mcr-1.4",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.19": {
        "display_name": "mcr-1.19",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.17": {
        "display_name": "mcr-1.17",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.24": {
        "display_name": "mcr-1.24",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.13": {
        "display_name": "mcr-1.13",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.21": {
        "display_name": "mcr-1.21",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.16": {
        "display_name": "mcr-1.16",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.20": {
        "display_name": "mcr-1.20",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.7": {
        "display_name": "mcr-1.7",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.25": {
        "display_name": "mcr-1.25",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.14": {
        "display_name": "mcr-1.14",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.6": {
        "display_name": "mcr-1.6",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.15": {
        "display_name": "mcr-1.15",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.11": {
        "display_name": "mcr-1.11",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.26": {
        "display_name": "mcr-1.26",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.28": {
        "display_name": "mcr-1.28",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.23": {
        "display_name": "mcr-1.23",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.2": {
        "display_name": "mcr-1.2",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.22": {
        "display_name": "mcr-1.22",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.32": {
        "display_name": "mcr-1.32",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.30": {
        "display_name": "mcr-1.30",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.12": {
        "display_name": "mcr-1.12",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.29": {
        "display_name": "mcr-1.29",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.18": {
        "display_name": "mcr-1.18",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.35": {
        "display_name": "mcr-1.35",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.9": {
        "display_name": "mcr-1.9",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.3": {
        "display_name": "mcr-1.3",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.33": {
        "display_name": "mcr-1.33",
        "antibiotics": [
            "colistin"
        ]
    },
    "mcr-1.37": {
        "display_name": "mcr-1.37",
        "antibiotics": [
            "colistin"
        ]
    },
    "blaCTX-M-131": {
        "display_name": "blaCTX-M-131",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-97": {
        "display_name": "blaCTX-M-97",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-37": {
        "display_name": "blaCTX-M-37",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-51": {
        "display_name": "blaCTX-M-51",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-155": {
        "display_name": "blaCTX-M-155",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-157": {
        "display_name": "blaCTX-M-157",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-270": {
        "display_name": "blaCTX-M-270",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-67": {
        "display_name": "blaCTX-M-67",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-15": {
        "display_name": "blaCTX-M-15",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-223": {
        "display_name": "blaCTX-M-223",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-91": {
        "display_name": "blaCTX-M-91",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-144": {
        "display_name": "blaCTX-M-144",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-34": {
        "display_name": "blaCTX-M-34",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-92": {
        "display_name": "blaCTX-M-92",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-88": {
        "display_name": "blaCTX-M-88",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-127": {
        "display_name": "blaCTX-M-127",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-203": {
        "display_name": "blaCTX-M-203",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-59": {
        "display_name": "blaCTX-M-59",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-87": {
        "display_name": "blaCTX-M-87",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-198": {
        "display_name": "blaCTX-M-198",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-46": {
        "display_name": "blaCTX-M-46",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-73": {
        "display_name": "blaCTX-M-73",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-138": {
        "display_name": "blaCTX-M-138",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-228": {
        "display_name": "blaCTX-M-228",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-89": {
        "display_name": "blaCTX-M-89",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-195": {
        "display_name": "blaCTX-M-195",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-96": {
        "display_name": "blaCTX-M-96",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-21": {
        "display_name": "blaCTX-M-21",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-19": {
        "display_name": "blaCTX-M-19",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-156": {
        "display_name": "blaCTX-M-156",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-143": {
        "display_name": "blaCTX-M-143",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-184": {
        "display_name": "blaCTX-M-184",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-205": {
        "display_name": "blaCTX-M-205",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-4": {
        "display_name": "blaCTX-M-4",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-30": {
        "display_name": "blaCTX-M-30",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-132": {
        "display_name": "blaCTX-M-132",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-233": {
        "display_name": "blaCTX-M-233",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-166": {
        "display_name": "blaCTX-M-166",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-100": {
        "display_name": "blaCTX-M-100",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-86": {
        "display_name": "blaCTX-M-86",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-24": {
        "display_name": "blaCTX-M-24",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-115": {
        "display_name": "blaCTX-M-115",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-196": {
        "display_name": "blaCTX-M-196",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-122": {
        "display_name": "blaCTX-M-122",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-44": {
        "display_name": "blaCTX-M-44",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-172": {
        "display_name": "blaCTX-M-172",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-248": {
        "display_name": "blaCTX-M-248",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-35": {
        "display_name": "blaCTX-M-35",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-104": {
        "display_name": "blaCTX-M-104",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-239": {
        "display_name": "blaCTX-M-239",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-43": {
        "display_name": "blaCTX-M-43",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-142": {
        "display_name": "blaCTX-M-142",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-147": {
        "display_name": "blaCTX-M-147",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-221": {
        "display_name": "blaCTX-M-221",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-259": {
        "display_name": "blaCTX-M-259",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-117": {
        "display_name": "blaCTX-M-117",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-197": {
        "display_name": "blaCTX-M-197",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-111": {
        "display_name": "blaCTX-M-111",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-150": {
        "display_name": "blaCTX-M-150",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-182": {
        "display_name": "blaCTX-M-182",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-65": {
        "display_name": "blaCTX-M-65",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUC-2": {
        "display_name": "blaKLUC-2",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-181": {
        "display_name": "blaCTX-M-181",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-158": {
        "display_name": "blaCTX-M-158",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-263": {
        "display_name": "blaCTX-M-263",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-58": {
        "display_name": "blaCTX-M-58",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-2": {
        "display_name": "blaCTX-M-2",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-136": {
        "display_name": "blaCTX-M-136",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-63": {
        "display_name": "blaCTX-M-63",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-90": {
        "display_name": "blaCTX-M-90",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-137": {
        "display_name": "blaCTX-M-137",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-249": {
        "display_name": "blaCTX-M-249",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-225": {
        "display_name": "blaCTX-M-225",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-215": {
        "display_name": "blaCTX-M-215",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUC-4": {
        "display_name": "blaKLUC-4",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-229": {
        "display_name": "blaCTX-M-229",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-217": {
        "display_name": "blaCTX-M-217",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-253": {
        "display_name": "blaCTX-M-253",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-179": {
        "display_name": "blaCTX-M-179",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-226": {
        "display_name": "blaCTX-M-226",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-235": {
        "display_name": "blaCTX-M-235",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-125": {
        "display_name": "blaCTX-M-125",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-112": {
        "display_name": "blaCTX-M-112",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-209": {
        "display_name": "blaCTX-M-209",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-53": {
        "display_name": "blaCTX-M-53",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-199": {
        "display_name": "blaCTX-M-199",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-201": {
        "display_name": "blaCTX-M-201",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-98": {
        "display_name": "blaCTX-M-98",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-76": {
        "display_name": "blaCTX-M-76",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-84": {
        "display_name": "blaCTX-M-84",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-79": {
        "display_name": "blaCTX-M-79",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-129": {
        "display_name": "blaCTX-M-129",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-167": {
        "display_name": "blaCTX-M-167",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-237": {
        "display_name": "blaCTX-M-237",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-246": {
        "display_name": "blaCTX-M-246",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-42": {
        "display_name": "blaCTX-M-42",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-70": {
        "display_name": "blaCTX-M-70",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-213": {
        "display_name": "blaCTX-M-213",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-72": {
        "display_name": "blaCTX-M-72",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-267": {
        "display_name": "blaCTX-M-267",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-148": {
        "display_name": "blaCTX-M-148",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-8": {
        "display_name": "blaCTX-M-8",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-94": {
        "display_name": "blaCTX-M-94",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-134": {
        "display_name": "blaCTX-M-134",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-105": {
        "display_name": "blaCTX-M-105",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-266": {
        "display_name": "blaCTX-M-266",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-222": {
        "display_name": "blaCTX-M-222",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-244": {
        "display_name": "blaCTX-M-244",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-193": {
        "display_name": "blaCTX-M-193",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-211": {
        "display_name": "blaCTX-M-211",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-16": {
        "display_name": "blaCTX-M-16",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUC-1": {
        "display_name": "blaKLUC-1",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-173": {
        "display_name": "blaCTX-M-173",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-212": {
        "display_name": "blaCTX-M-212",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-78": {
        "display_name": "blaCTX-M-78",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-265": {
        "display_name": "blaCTX-M-265",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-269": {
        "display_name": "blaCTX-M-269",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-168": {
        "display_name": "blaCTX-M-168",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-260": {
        "display_name": "blaCTX-M-260",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-80": {
        "display_name": "blaCTX-M-80",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-231": {
        "display_name": "blaCTX-M-231",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-60": {
        "display_name": "blaCTX-M-60",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-28": {
        "display_name": "blaCTX-M-28",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-234": {
        "display_name": "blaCTX-M-234",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-214": {
        "display_name": "blaCTX-M-214",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-152": {
        "display_name": "blaCTX-M-152",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-13": {
        "display_name": "blaCTX-M-13",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-23": {
        "display_name": "blaCTX-M-23",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-188": {
        "display_name": "blaCTX-M-188",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-103": {
        "display_name": "blaCTX-M-103",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-110": {
        "display_name": "blaCTX-M-110",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-219": {
        "display_name": "blaCTX-M-219",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-254": {
        "display_name": "blaCTX-M-254",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-169": {
        "display_name": "blaCTX-M-169",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-176": {
        "display_name": "blaCTX-M-176",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-36": {
        "display_name": "blaCTX-M-36",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUG-1": {
        "display_name": "blaKLUG-1",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-121": {
        "display_name": "blaCTX-M-121",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-262": {
        "display_name": "blaCTX-M-262",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-81": {
        "display_name": "blaCTX-M-81",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-66": {
        "display_name": "blaCTX-M-66",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-47": {
        "display_name": "blaCTX-M-47",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-9": {
        "display_name": "blaCTX-M-9",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-236": {
        "display_name": "blaCTX-M-236",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-6": {
        "display_name": "blaCTX-M-6",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-130": {
        "display_name": "blaCTX-M-130",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-190": {
        "display_name": "blaCTX-M-190",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-32": {
        "display_name": "blaCTX-M-32",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-210": {
        "display_name": "blaCTX-M-210",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-218": {
        "display_name": "blaCTX-M-218",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-178": {
        "display_name": "blaCTX-M-178",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-238": {
        "display_name": "blaCTX-M-238",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-191": {
        "display_name": "blaCTX-M-191",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUC-5": {
        "display_name": "blaKLUC-5",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-141": {
        "display_name": "blaCTX-M-141",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-230": {
        "display_name": "blaCTX-M-230",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-102": {
        "display_name": "blaCTX-M-102",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-17": {
        "display_name": "blaCTX-M-17",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-202": {
        "display_name": "blaCTX-M-202",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-227": {
        "display_name": "blaCTX-M-227",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-258": {
        "display_name": "blaCTX-M-258",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-114": {
        "display_name": "blaCTX-M-114",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-204": {
        "display_name": "blaCTX-M-204",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-75": {
        "display_name": "blaCTX-M-75",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-206": {
        "display_name": "blaCTX-M-206",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-124": {
        "display_name": "blaCTX-M-124",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-126": {
        "display_name": "blaCTX-M-126",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-56": {
        "display_name": "blaCTX-M-56",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-164": {
        "display_name": "blaCTX-M-164",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-64": {
        "display_name": "blaCTX-M-64",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-95": {
        "display_name": "blaCTX-M-95",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-40": {
        "display_name": "blaCTX-M-40",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUC-6": {
        "display_name": "blaKLUC-6",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-3": {
        "display_name": "blaCTX-M-3",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-5": {
        "display_name": "blaCTX-M-5",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-12": {
        "display_name": "blaCTX-M-12",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-74": {
        "display_name": "blaCTX-M-74",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-162": {
        "display_name": "blaCTX-M-162",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-245": {
        "display_name": "blaCTX-M-245",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-154": {
        "display_name": "blaCTX-M-154",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-29": {
        "display_name": "blaCTX-M-29",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-10": {
        "display_name": "blaCTX-M-10",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-160": {
        "display_name": "blaCTX-M-160",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-261": {
        "display_name": "blaCTX-M-261",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-220": {
        "display_name": "blaCTX-M-220",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-242": {
        "display_name": "blaCTX-M-242",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-1": {
        "display_name": "blaCTX-M-1",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-62": {
        "display_name": "blaCTX-M-62",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-31": {
        "display_name": "blaCTX-M-31",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-38": {
        "display_name": "blaCTX-M-38",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-240": {
        "display_name": "blaCTX-M-240",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-22": {
        "display_name": "blaCTX-M-22",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-48": {
        "display_name": "blaCTX-M-48",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-200": {
        "display_name": "blaCTX-M-200",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-186": {
        "display_name": "blaCTX-M-186",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-251": {
        "display_name": "blaCTX-M-251",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-241": {
        "display_name": "blaCTX-M-241",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-159": {
        "display_name": "blaCTX-M-159",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-174": {
        "display_name": "blaCTX-M-174",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-232": {
        "display_name": "blaCTX-M-232",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-255": {
        "display_name": "blaCTX-M-255",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-85": {
        "display_name": "blaCTX-M-85",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-256": {
        "display_name": "blaCTX-M-256",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-99": {
        "display_name": "blaCTX-M-99",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-20": {
        "display_name": "blaCTX-M-20",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-243": {
        "display_name": "blaCTX-M-243",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-14": {
        "display_name": "blaCTX-M-14",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-252": {
        "display_name": "blaCTX-M-252",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-151": {
        "display_name": "blaCTX-M-151",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-77": {
        "display_name": "blaCTX-M-77",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-101": {
        "display_name": "blaCTX-M-101",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-123": {
        "display_name": "blaCTX-M-123",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-153": {
        "display_name": "blaCTX-M-153",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-165": {
        "display_name": "blaCTX-M-165",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-33": {
        "display_name": "blaCTX-M-33",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-180": {
        "display_name": "blaCTX-M-180",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-257": {
        "display_name": "blaCTX-M-257",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-69": {
        "display_name": "blaCTX-M-69",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-177": {
        "display_name": "blaCTX-M-177",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-26": {
        "display_name": "blaCTX-M-26",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-49": {
        "display_name": "blaCTX-M-49",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-116": {
        "display_name": "blaCTX-M-116",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-208": {
        "display_name": "blaCTX-M-208",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-194": {
        "display_name": "blaCTX-M-194",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-247": {
        "display_name": "blaCTX-M-247",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-189": {
        "display_name": "blaCTX-M-189",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-52": {
        "display_name": "blaCTX-M-52",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-161": {
        "display_name": "blaCTX-M-161",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-50": {
        "display_name": "blaCTX-M-50",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-61": {
        "display_name": "blaCTX-M-61",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-268": {
        "display_name": "blaCTX-M-268",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-113": {
        "display_name": "blaCTX-M-113",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-93": {
        "display_name": "blaCTX-M-93",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-192": {
        "display_name": "blaCTX-M-192",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-163": {
        "display_name": "blaCTX-M-163",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-175": {
        "display_name": "blaCTX-M-175",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-27": {
        "display_name": "blaCTX-M-27",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-224": {
        "display_name": "blaCTX-M-224",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-185": {
        "display_name": "blaCTX-M-185",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-68": {
        "display_name": "blaCTX-M-68",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-55": {
        "display_name": "blaCTX-M-55",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaKLUC-3": {
        "display_name": "blaKLUC-3",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-187": {
        "display_name": "blaCTX-M-187",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-171": {
        "display_name": "blaCTX-M-171",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-170": {
        "display_name": "blaCTX-M-170",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-146": {
        "display_name": "blaCTX-M-146",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-207": {
        "display_name": "blaCTX-M-207",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-25": {
        "display_name": "blaCTX-M-25",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-71": {
        "display_name": "blaCTX-M-71",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-139": {
        "display_name": "blaCTX-M-139",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-83": {
        "display_name": "blaCTX-M-83",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-41": {
        "display_name": "blaCTX-M-41",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-54": {
        "display_name": "blaCTX-M-54",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-264": {
        "display_name": "blaCTX-M-264",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-82": {
        "display_name": "blaCTX-M-82",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-183": {
        "display_name": "blaCTX-M-183",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-39": {
        "display_name": "blaCTX-M-39",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-216": {
        "display_name": "blaCTX-M-216",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-7": {
        "display_name": "blaCTX-M-7",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "blaCTX-M-140": {
        "display_name": "blaCTX-M-140",
        "antibiotics": [
            "cefazolin",
            "cefepime",
            "cefixime",
            "cefotaxime",
            "cefoxitin",
            "ceftazidime",
            "ceftriaxone",
            "cefuroxime",
            "amoxicillin",
            "ampicillin",
            "penicillin"
        ]
    },
    "mecA": {
        "display_name": "mecA",
        "antibiotics": [
            "methicillin",
            "oxacillin"
        ]
    },
    "mecA-ceftar": {
        "display_name": "mecA-ceftar",
        "antibiotics": [
            "methicillin",
            "oxacillin"
        ]
    },
    "mecC": {
        "display_name": "mecC",
        "antibiotics": [
            "methicillin",
            "oxacillin"
        ]
    },
    "vanA": {
        "display_name": "vanA",
        "antibiotics": [
            "vancomycin"
        ]
    },
    "vanB": {
        "display_name": "vanB",
        "antibiotics": [
            "vancomycin"
        ]
    }
}
