import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

function IdentifiedSpeciesFiltersDialog(props) {
    const {
        isIllumina,
        open,
        setOpen,  
        showAST,
        setShowAST,
        showKIDCalls,
        setShowKIDCalls,
        visibleSpeciesCt,
        setVisibleSpeciesCt,
        showAlignmentMetrics,
        setShowAlignmentMetrics,
        showCoverageBreadth,
        setShowCoverageBreadth,
        showCoverageDepth,
        setShowCoverageDepth,
        showRnDModels,
        setShowRnDModels,
        showImputedCalls,
        setShowImputedCalls,
        identifiedSpecies,
        showAssemblyMetrics,
        setShowAssemblyMetrics,
        showAssemblyMBsToSpecies,
        setShowAssemblyMBsToSpecies,
        showContigsToSpecies,
        setShowContigsToSpecies,
        showResistanceGenes,
        setShowResistanceGenes
    } = props;

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const positiveCallsCt = identifiedSpecies ? identifiedSpecies.filter(item => item.attributes.species_call_positive).length : 5

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        >
            <DialogTitle>Results Display</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid xs={12} sx={{paddingRight: "20px"}}>
                    <p style={{marginBottom: "50px"}}><b>Visible Species / Complexes</b></p>
                        <Slider
                            valueLabelDisplay="on"
                          aria-label="Visible species"
                          value={visibleSpeciesCt}
                          getAriaValueText={(value) => `${value} species`}
                            marks
                          step={1}
                            min={1}
                            max={identifiedSpecies ? identifiedSpecies.length : 20}
                          onChange={(event, newValue) => setVisibleSpeciesCt(newValue)}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <p>Jump to:</p>
                        {isIllumina ? null : (
                      <Button size="small" variant="contained" sx={{marginRight: "10px"}} disabled={!showKIDCalls} onClick={() => setVisibleSpeciesCt(positiveCallsCt)}>Positive Calls Only</Button>
                        )}
                      <Button size="small" variant="contained" onClick={() => setVisibleSpeciesCt(identifiedSpecies.length)}>All Identified Organisms</Button>
                    </Grid>
                    <Grid xs={12} sx={{padding: "15px"}}>
                    <Divider />
    </Grid>
                   <Grid xs={6}> 
                    <p><b>Species Identification</b></p>
                        {isIllumina ? null : (
                            <>
                      <FormGroup sx={{paddingLeft: "20px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showKIDCalls} onChange={event => setShowKIDCalls(event.target.checked)} />} label="Keynome ID Calls" labelPlacement="end" />
                      </FormGroup>
                        <br />
                            </>
                        )}
                        {isIllumina ? (
                            <>
                      <FormGroup sx={{paddingLeft: "20px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAssemblyMetrics} onChange={event => setShowAssemblyMetrics(event.target.checked)} />} label="Assembly Metrics" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={(showAssemblyMetrics && showAssemblyMBsToSpecies)} onChange={event => setShowAssemblyMBsToSpecies(event.target.checked)} disabled={!showAssemblyMetrics} />} label="MBs to Organism" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAssemblyMetrics && showContigsToSpecies} onChange={event => setShowContigsToSpecies(event.target.checked)} disabled={!showAssemblyMetrics} />} label="Contigs to Organism" labelPlacement="end" />
                      </FormGroup>
                        </>) : null}
                        {isIllumina ? null : (
                            <>
                      <FormGroup sx={{paddingLeft: "20px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAlignmentMetrics} onChange={event => setShowAlignmentMetrics(event.target.checked)} />} label="Alignment Metrics" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={(showAlignmentMetrics && showCoverageBreadth)} onChange={event => setShowCoverageBreadth(event.target.checked)} disabled={!showAlignmentMetrics} />} label="Coverage Breadth" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAlignmentMetrics && showCoverageDepth} onChange={event => setShowCoverageDepth(event.target.checked)} disabled={!showAlignmentMetrics} />} label="Average Coverage Depth" labelPlacement="end" />
                      </FormGroup>
                        </>)
                    }
                    </Grid>
                   <Grid xs={6}> 
                    <p><b>Predictive AST</b></p>
                      <FormGroup sx={{paddingLeft: "20px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAST} onChange={event => setShowAST(event.target.checked)} />} label={(<>Keynome <i>g</i>AST Calls</>)} labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAST} disabled={true} />} label="Qualified Models" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAST && showRnDModels} onChange={event => setShowRnDModels(event.target.checked)} disabled={!showAST} />} label="R&D Models" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAST && showImputedCalls} onChange={event => setShowImputedCalls(event.target.checked)} disabled={!showAST} />} label="Imputed Calls" labelPlacement="end" />
                      </FormGroup>
                      <FormGroup sx={{paddingLeft: "40px"}}>
                        <FormControlLabel control={<Checkbox size="small" checked={showAST && showResistanceGenes} onChange={event => setShowResistanceGenes(event.target.checked)} />} disabled={!showAST} label="Resistance Genes" labelPlacement="end" />
                      </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{marginBottom: "15px"}}>
                <Button variant="contained" color="primary" sx={{marginRight: "20px"}} onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );

}

export default IdentifiedSpeciesFiltersDialog;
