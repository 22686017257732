import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

import RunStatusList from './Runs/RunStatusList';
import AddSamplesDialog from './Samples/AddSamplesDialog';
import AddRunDialog from './Runs/AddRunDialog';
import SmallRunStatusIndicator from './Runs/SmallRunStatusIndicator';

import {
    sequencerLabelToName,
    flowcellLabelToName,
    barcodingKitLabelToName,
    libraryPrepKitLabelToName
} from './Runs/canonicalizeMetadata.js'

import ontLogo from './ont_logo.svg';
import illuminaLogo from './illumina_logo.svg';

function Project() {
  const {
      activeProject,
      visibleRuns,
      addSamplesDialogOpen,
      setAddSamplesDialogOpen,
      activeRun,
      setActiveRun,
      userData,
      uploadFile,
      uploadingFilesCt,
      runs,
      setReloadRuns,
      samples,
      setSamples,
      datasetsGridRef,
      datasetsGridRows,
      datasetStatusRef,
      setLoadStatuses
  } = useOutletContext();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [addRunDialogOpen, setAddRunDialogOpen] = useState(false);
  const [reloadSamples, setReloadSamples] = useState(false);

  useEffect(() => {
    if (!activeRun) {
      return;
    }

  if (reloadSamples) {
    setSamples(null);
      setReloadSamples(false);
      return;
  }

    getAccessTokenSilently().then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${activeRun.id}/lib_seqs`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        setSamples(response.data.sort((a, b) => a.id - b.id));
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeRun, setSamples, reloadSamples, setReloadSamples]);

  let visibleRunsContent = null;
  if (visibleRuns) {
    visibleRunsContent = (
        <RunStatusList
          runs={visibleRuns}
          activeRun={activeRun}
          setActiveRun={setActiveRun} 
          setSamples={setSamples} 
        />
    )
  }

    const dataGridColumns = [
        {
            field: 'name',
            headerName: 'Dataset Name',
            minWidth: 300,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Dataset Name</b>
            ),
            renderCell: cell => (
                <b>{cell.value}</b>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Status</b>
            ),
            headerAlign: 'center',
            align: 'center',
            renderCell: cell => {
                return <SmallRunStatusIndicator status={cell.value || datasetStatusRef[cell.row.id]} />
            }
        },
        {
            field: 'platform',
            headerName: 'Platform',
            width: 200,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Platform</b>
            ),
            headerAlign: 'center',
            align: 'center',
            renderCell: cell => (
                <>
                {cell.value ? (
                <img src={cell.value === 'illumina' ? illuminaLogo : ontLogo} alt="platform-logo" height="20px" style={{marginBottom: '-5px'}} /> ) : null}
                {' '}
                {cell.value ? cell.value.toUpperCase() : '-'}
                </>
            )
        },
        {
            field: 'sequencer_name',
            headerName: 'Sequencer',
            width: 200,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Sequencer</b>
            ),
            valueGetter: value => {
                return sequencerLabelToName[value] || value
            },
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'flowcell',
            headerName: 'Flow Cell',
            width: 200,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Flow Cell</b>
            ),
            valueGetter: value => {
                return flowcellLabelToName[value] || value
            },
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'barcode_kit',
            headerName: 'Barcode/Index Kit',
            width: 200,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Barcode / Index Kit</b>
            ),
            valueGetter: value => {
                return barcodingKitLabelToName[value] || value
            },
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'library_prep_kit',
            headerName: 'Library Prep Kit',
            width: 200,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Library Prep Kit</b>
            ),
            valueGetter: value => {
                return libraryPrepKitLabelToName[value] || value
            },
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 300,
            resizable: false,
            headerClassName: 'runs-table--header',
            renderHeader: value => (
                <b>Created At</b>
            ),
            headerAlign: 'center',
            align: 'center',
            type: 'dateTime'
        },
    ]


  return (
    <Grid container maxWidth="lg" spacing={2} sx={{ m: 'auto', mt: 1, paddingRight: '30px' }}>
      <Grid item xs={12} sx={{marginTop: '50px'}}>
        <p style={{color: '#797979', marginBottom: '2px', marginTop: '2px'}}>
            <b>RECENTLY VIEWED DATASETS</b>
        </p>
      </Grid>
      {visibleRunsContent}
      <Grid item xs={12} sm={8} sx={{ marginTop: '50px'}}>
        <p style={{color: '#797979', marginBottom: '2px', marginTop: '2px'}}><b>SEQUENCING DATASETS</b>
      </p>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ textAlign: 'right'}}>
            {activeRun ? null : (
            <Button size="large" sx={{marginRight: '10px', marginTop: '30px'}} variant="contained" color="info" aria-label="upload" onClick={() => setAddRunDialogOpen(true)} startIcon={<AddIcon />}>Create New Dataset</Button>)}
        </Box>
      </Grid>
      <Grid item xs={12} height="665px" sx={{'& .runs-table--header': { backgroundColor: "#eee" }, marginBottom: '40px'}}>
        <DataGrid autoPageSize columns={dataGridColumns} rows={datasetsGridRows} apiRef={datasetsGridRef}
                onRowClick={(params) => {
                    setAddSamplesDialogOpen(true)
                    setActiveRun({'id': params.row.id, 'attributes': {platform: params.row.platform, name: params.row.name, sequencer_name: params.row.sequencer_name, minion_sn: params.row.sequencer_sn}})
                }}
              loading={!runs}
              slotProps={{
                  loadingOverlay: {
                      variant: 'skeleton',
                    noRowsVariant: 'skeleton',
                  },
              }}
            onPaginationModelChange={() => setLoadStatuses(true)}
      sx={{
          [`& .${gridClasses.cell}`]: { cursor: 'pointer' },
          [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                    outline: 'transparent',
                  
          },
              [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                        outline: 'none',
                      
              },
      }}
        />
      </Grid>
      <AddSamplesDialog open={addSamplesDialogOpen || activeRun} setOpen={setAddSamplesDialogOpen} activeRun={activeRun} reloadSamples={() => setReloadSamples(true)} uploadFile={uploadFile} backgroundUploadingFilesCt={uploadingFilesCt}  samples={samples} activeProject={activeProject} setSamples={setSamples} setActiveRun={setActiveRun} setReloadRuns={setReloadRuns} />
      <AddRunDialog open={addRunDialogOpen} setOpen={setAddRunDialogOpen} activeProject={activeProject} userData={userData} reloadRuns={() => setReloadRuns(true)} />
    </Grid>
  );
}

export default Project;
